<template>
  <div class="alert-shop" :class="{active}">

    <div class="alert-top">
      <h3 v-if="!isRegion">{{ 'categories' | localize}}</h3>
      <h3 v-else>{{ 'regionChoose' | localize}}</h3>

      <span @click="closeAlert">
        <img src="../../assets/profile_assets/closeSidebar.svg" alt="" />
      </span>
    </div>

    <ul>
      <li
        v-for="(category, index) in categories"
        :key="category.id"
        class="list"
        @click="filterCategory(category.id, category.locale.title)"
      >
        <div class="left">
          <div v-if="!isRegion" class="icon">
            <img :src="categoryIcons[index < 12 ? index : 11]" :alt="category.locale.title">
            <!--<svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1.5C7.72386 1.5 7.5 1.27614 7.5 1C7.5 0.723858 7.72386 0.5 8 0.5L8 1.5ZM11.5 4C11.5 4.27614 11.2761 4.5 11 4.5C10.7239 4.5 10.5 4.27614 10.5 4L11.5 4ZM8 11.5C7.72386 11.5 7.5 11.2761 7.5 11C7.5 10.7239 7.72386 10.5 8 10.5L8 11.5ZM10.5 8C10.5 7.72386 10.7239 7.5 11 7.5C11.2761 7.5 11.5 7.72386 11.5 8L10.5 8ZM4 0.5C4.27614 0.5 4.5 0.723858 4.5 1C4.5 1.27614 4.27614 1.5 4 1.5L4 0.5ZM1.5 4C1.5 4.27614 1.27614 4.5 1 4.5C0.723858 4.5 0.5 4.27614 0.5 4L1.5 4ZM4 10.5C4.27614 10.5 4.5 10.7239 4.5 11C4.5 11.2761 4.27614 11.5 4 11.5L4 10.5ZM0.5 8C0.5 7.72386 0.723858 7.5 1 7.5C1.27614 7.5 1.5 7.72386 1.5 8L0.5 8ZM8 0.5L10 0.5L10 1.5L8 1.5L8 0.5ZM11.5 2L11.5 4L10.5 4L10.5 2L11.5 2ZM10 0.5C10.8284 0.5 11.5 1.17157 11.5 2L10.5 2C10.5 1.72386 10.2761 1.5 10 1.5L10 0.5ZM8 10.5L10 10.5L10 11.5L8 11.5L8 10.5ZM10.5 10L10.5 8L11.5 8L11.5 10L10.5 10ZM10 10.5C10.2761 10.5 10.5 10.2761 10.5 10L11.5 10C11.5 10.8284 10.8284 11.5 10 11.5L10 10.5ZM4 1.5L2 1.5L2 0.5L4 0.5L4 1.5ZM1.5 2L1.5 4L0.5 4L0.5 2L1.5 2ZM2 1.5C1.72386 1.5 1.5 1.72386 1.5 2L0.5 2C0.5 1.17157 1.17157 0.5 2 0.5L2 1.5ZM4 11.5L2 11.5L2 10.5L4 10.5L4 11.5ZM0.5 10L0.5 8L1.5 8L1.5 10L0.5 10ZM2 11.5C1.17157 11.5 0.5 10.8284 0.5 10L1.5 10C1.5 10.2761 1.72386 10.5 2 10.5L2 11.5Z"
                fill="white"
              />
            </svg>-->
          </div>

          <span>{{ category.locale.title }}</span>

        </div>

        <!--<div class="right">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16 8H15V16H8V17H15V24H16V17L24 17V16L16 16V8Z"
              fill="#1E1E1E"
            />
          </svg>
        </div>-->
      </li>
    </ul>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import categoryIcons from '../../data/category-icons';

export default {
  name: 'AlertShopCategory',
  props: {
    categories: VueTypes.array.isRequired,
    active: VueTypes.bool,
    isRegion: VueTypes.bool.def(false),
  },
  data: () => ({
    categoryIcons,
  }),
  methods: {
    filterCategory(categoryId, categoryTitle) {
      this.$emit('filter-category', { categoryId, categoryTitle });
    },
    closeAlert() {
      this.$emit('close-alert', false);
    },
  },
};
</script>

<style lang="scss" scoped>
  .alert-shop {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white;
    padding: 12px 24px;
    transform: translateY(-200vh);
    transition: 0.5s;

    &.active {
      transform: translateY(0vh);
    }

    .alert-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h3 {
      font-weight: 900;
      font-size: 24px;
      line-height: 30px;
      color: $black;
      margin-bottom: 12px;
    }

    ul {
      overflow-y: scroll;
      height: 100%;
      padding-bottom: 40px;
    }
  }

  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: pointer;
    transition: 0.3s;

    .left {
      display: flex;
      align-items: center;
    }

    &:hover {
      color: $main;
    }

    .icon {
      min-width: 32px;
      min-height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $main;
      border-radius: 8px;
      margin-right: 8px;
    }
  }
</style>
