<template>
  <div class="shop">

    <header>
      <h1>{{ localize("banner")["magazines"]["title"] }}</h1>
    </header>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-ml-3 col-lg-4">
            <div class="card_category">

              <h2>{{ localize("categories") }}</h2>

              <categories-list
                :categories="categories"
                :loading="loading"
                @onChange="filterPartners"
              />

            </div>
          </div>

          <div class="col-xl-9 col-ml-9 col-lg-8">
            <Search
              :placeholder="localize('input')['search-placeholder']"
              :is-region="true"
              :region="region"
              @search-handler="searchHandler"
              @open-dropdown="openRegions"
            />

            <div class="row region-dropdown-content" style="display: none">
              <div class="col-12">
                <p class="region-dropdown-content__title">{{ localize("choose-region") }}</p>
              </div>

              <div class="col-md-4">
                <p class="cursor-pointer" v-for="({ id, name }) in regions.slice(0, 5)"
                   :key="id"
                   @click="closeRegions(id, name)"
                >
                  {{ name }}
                </p>
              </div>

              <div class="col-md-4">
                <p class="cursor-pointer" v-for="({ id, name }) in regions.slice(5, 10)"
                   :key="id"
                   @click="closeRegions(id, name)"
                >
                  {{ name }}
                </p>
              </div>

              <div class="col-md-4">
                <p class="cursor-pointer" v-for="({ id, name }) in regions.slice(10, 14)"
                   :key="id"
                   @click="closeRegions(id, name)"
                >
                  {{ name }}
                </p>
              </div>
            </div>

            <div class="row">

              <div class="col-6">
                <button class="p-button" @click="openAlert">

                  {{ localize("categories") }}

                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.50391 5.57001L15.9339 12L9.50391 18.43"
                      stroke="white"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>

              <div class="col-6">
                <button class="p-button" @click="showRegions = true">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M12.0001 12.7158C10.9572 12.7158 10.1083 11.8622 10.1083 10.8136C10.1083 9.76505 10.9572 8.91146 12.0001 8.91146C13.043 8.91146 13.892 9.76505 13.892 10.8136C13.892 11.8622 13.043 12.7158 12.0001 12.7158ZM11.9998 7.96028C10.4359 7.96028 9.16208 9.24027 9.16208 10.8135C9.16208 12.3868 10.4359 13.6667 11.9998 13.6667C13.5638 13.6667 14.8376 12.3868 14.8376 10.8135C14.8376 9.24027 13.5638 7.96028 11.9998 7.96028ZM16.2833 15.3447L11.9998 19.6515L7.71638 15.3447C5.35474 12.9702 5.35474 9.10641 7.71638 6.73189C8.89721 5.54383 10.4477 4.9502 11.9998 4.9502C13.5511 4.9502 15.1024 5.54462 16.2833 6.73189C18.6449 9.10641 18.6449 12.9702 16.2833 15.3447ZM16.9527 6.05908C14.2213 3.31364 9.77867 3.31364 7.04732 6.05908C4.31756 8.80453 4.31756 13.2722 7.04732 16.0177L11.6658 20.6605C11.758 20.754 11.8794 20.8 12 20.8C12.1206 20.8 12.242 20.754 12.3342 20.6605L16.9527 16.0177C19.6824 13.2722 19.6824 8.80453 16.9527 6.05908Z"
                          :fill="scssVars.white"
                          :style="{ minWidth: '24px', minHeight: '24px' }"
                    />
                  </svg>

                  <span v-if="region.length <= 10">{{ region }}</span>

                  <marquee v-else scrollamount="3">{{ region }}</marquee>

                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.50391 5.57001L15.9339 12L9.50391 18.43"
                      :stroke="scssVars.white"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>

            </div>

            <p v-if="!title" class="category-title">{{ categoryTitle }}</p>

            <div v-if="loading" class="row">
              <div v-for="item in 3" class="col-md-4" :key="item">
                <pu-skeleton
                  :loading="loading"
                  class="skeleton-loader"
                  tag="div"
                  height="100%"
                ></pu-skeleton>
              </div>
            </div>

            <template v-else>
              <div v-if="!noResult" :class="['row', title ? 'mt-32' : '' ]">
                <div
                  class="col-md-4"
                  v-for="partner in items"
                  :key="partner.id"
                >
                  <Card
                    :id="partner.id"
                    :logo="partner.logo ? partner.logo.path : ''"
                    :title="partner.brand || 'Не задано'"
                    :partner-categories="partner.categories && partner.categories.length > 0 ? partner.categories : []"
                    :categories="categories"
                    :loading="loading"
                  />
                </div>
              </div>

              <div v-else class="no-result">
                {{ localize("not-found") }}
              </div>
            </template>

            <paginate
              :page-count="pageCount"
              :click-handler="pageChangeHandler"
              :prev-text="prevButton"
              :next-text="nextButton"
              prev-class="prev"
              next-class="next"
              :container-class="'pagination'"
              :page-class="'sum'"
            />

          </div>
        </div>
      </div>

      <alert-shop-category
        :active="active"
        @close-alert="closeAlert"
        @filter-category="filterPartners"
        :categories="categories"
      />

      <alert-shop-category
        :active="showRegions"
        @close-alert="closeAlert"
        @filter-category="filterRegions"
        :categories="updatedRegions"
        :is-region="true"
      />

    </section>
  </div>
</template>

<script>
import $ from "jquery";
import localize from "../filters/localize.filter";
import paginationMixin from "../mixins/pagination.mixin.js";
import { mapGetters } from "vuex";
import search from "../services/search";
import { scssVars } from "@/utils/constants";

import Card from "../components/card.vue";
import CategoriesList from "../components/menus/CategoriesList";
import Search from "../components/shop_section/searchCategory.vue";
import AlertShopCategory from "../components/shop_section/AlertShopCategory.vue";

export default {
  name: "PartnersPage",
  mixins: [paginationMixin],
  data: () => ({
    scssVars,
    active: false,
    showRegions: false,
    categories: [],
    categoryTitle: "",
    partnersData: [],
    noResult: false,
    title: false,
    loading: false,
    region: localize("overUZB"),
    regions: localize("regions"),
    prevButton: `
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.21497 13.4299L0.784973 6.99995L7.21497 0.569946" stroke="#6610F5" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        ${localize("button")["back"]}
      `,
    nextButton: `
        ${localize("button")["next"]}
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.504028 0.569946L6.93403 6.99995L0.504028 13.4299" stroke="#6610F5" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      `
  }),
  components: {
    CategoriesList,
    Search,
    Card,
    AlertShopCategory
  },
  computed: {
    ...mapGetters({ partners: "filter/result" }),
    updatedRegions() {
      return this.regions.map(({ id, name }) => {
        return {
          id,
          locale: {
            title: name
          }
        };
      });
    },
    isLoading() {
      return this.$store.getters["common/getLoading"];
    }
  },
  methods: {
    localize,
    searchHandler(partnerName) {
      let result = [];

      // if (this.partners !== null) {
      result = search(this.partnersData, "brand", partnerName);
      // } else {
      //   result = search(this.partnersData, 'brand', partnerName);
      // }

      if (result.length > 0) {
        this.noResult = false;
        this.title = true;
        // if we have result, we will remove title and showing result
        this.$store.dispatch("filter/set", result);
        this.setup();
      } else {
        // if we haven't result we will show 'no result' text
        this.noResult = true;
        this.$store.dispatch("filter/cancel");
        this.setup();
      }
      // if search field empty we will set default all partners
      if (!partnerName) {
        this.$store.dispatch("filter/set", this.partnersData);
      }
    },
    openRegions() {
      $(".region-dropdown-content").slideToggle(400);
    },
    closeRegions(regionId, region) {
      const filteredByRegion = this.partnersData.filter(({ region_id }) => region_id === regionId);
      this.$store.dispatch("filter/set", filteredByRegion);

      this.noResult = filteredByRegion.length === 0;

      this.region = region;

      $(".region-dropdown-content").slideUp();
    },
    setup() {
      this.setUpPaginetion(this.partners);
    },
    closeAlert(n) {
      this.active = n;
      this.showRegions = n;
    },
    openAlert() {
      this.active = true;
    },
    filterRegions({ categoryId, categoryTitle }) {
      this.closeRegions(categoryId, categoryTitle);
      this.closeAlert(false);
    },
    filterPartners({ categoryId, categoryTitle }) {

      if (categoryId) {
        // when user clicks category for filter, we show title
        this.title = false;
        this.categoryTitle = categoryTitle;
      } else {
        this.title = true;
      }

      const filteredPartners = [];
      // filter partners by category_id
      this.partnersData.forEach(partner => {
        if (partner.categories.some(category => category.catalog_id === categoryId)) {
          filteredPartners.push(partner);
        }
      });
      // set filtered partners
      this.$store.dispatch("filter/set", filteredPartners);
      this.setup();

      this.closeAlert(false);
    }
  },
  async created() {
    this.loading = true;
    try {
      // fetch all categories
      const { data: categories } = await this.$axios.get("categories/list", {
        headers: {
          "Content-Language": this.$i18n.locale
        }
      });

      if (categories.status === "success") {
        this.categories = categories.data.filter(category => category.parent === null);
      } else {
        categories.response.errors.forEach(error => this.$toastError(error));
      }
      // fetch all partners
      const { data: partners } = await this.$axios.get("partners/list");

      if (partners.status === "success") {
        this.partnersData = partners.data.filter(partner => partner.status !== 0 && partner.parent_id === null);

        // set default all partners
        await this.$store.dispatch("filter/set", this.partnersData);

      } else {
        partners.response.errors.forEach(error => this.$toastError(error));
      }

      this.loading = false;

    } catch (e) {
      this.loading = false;

      this.$toastError(e.message);
    }
    this.setup();
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins/font.scss";

.skeleton-loader {
  height: 220px;
  margin: 10px 0;
  border-radius: 14px;
}

.shop {
  .mt-32 {
    margin-top: 32px;
  }

  header {
    padding-bottom: 56px;
    padding-top: 56px;
    background: $grey url("../assets/icons/Vector.svg") no-repeat calc(50% - 120px) 37px;

    h1 {
      font-weight: 900;
      font-size: 40px;
      line-height: 40px;
      text-align: center;
      color: $black;
    }
  }

  section {
    padding-top: 64px;
    padding-bottom: 96px;

    .category-title {
      margin: 40px 0;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;

      @media (max-width: $mobile) {
        font-size: 20px;
        margin-top: 36px;
        margin-bottom: 8px;
      }
    }

    .card_category {
      background: $grey;
      border-radius: 8px;
      padding: 16px 12px;
      margin-bottom: 24px;
    }

    .region-dropdown-content {
      background: $grey;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      padding: 24px 8px;
      width: 100%;
      margin: 8px auto 0;

      &__title {
        @include font(16px, 700, 24px, $black);
        letter-spacing: 0.01em;
        margin-bottom: 8px;
      }

      p {
        line-height: 30px;
        transition: color .2s linear;

        &:hover {
          color: $main;
        }
      }
    }
  }
}

button.p-button {
  display: none;
}

@media (max-width: 575px) {
  .shop {
    button.p-button {
      cursor: pointer;
      background: $main;
      box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
      border-radius: 14px;
      width: 100%;
      padding: 14px 24px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      transition: 0.2s;
      border: none;
      margin-top: 16px;

      &:hover {
        background: $main-light;
      }
    }

    header {
      padding: 21px;

      h1 {
        font-weight: 900;
        font-size: 24px;
        line-height: 30px;
      }
    }

    section {
      padding-top: 16px;
      padding-bottom: 100px;

      .card_category {
        display: none;
      }
    }
  }
}
</style>

<style lang="scss">
.pagination {
  display: flex;
  margin-top: 23px;

  @media (max-width: 320px) {
    font-size: 13px;
  }

  .sum {
    &.active {
      color: $main;
    }

    a {
      padding: 6px;
      display: inline-block;

      &:focus {
        outline: none;
        border: none;
      }
    }
  }

  .prev a {
    color: $main;
    padding: 6px 20px 6px 6px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  .next a {
    color: $main;
    padding: 6px 6px 6px 20px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
    }

    &:focus {
      outline: none;
      border: none;
    }
  }
}
</style>
