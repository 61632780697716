import smartPhones from '../assets/icons/phone-3.svg';
import compTechnique from '../assets/icons/laptop-2.svg';
import gadgets from '../assets/icons/photo-camera.svg';
import conditioners from '../assets/icons/tablet.svg';
import tv from '../assets/icons/tv.svg';
import audioWiFi from '../assets/icons/microphone.svg';
import homeTechnique from '../assets/icons/iron.svg';
import officeTechnique from '../assets/icons/desk-chair.svg';
import kitchenTechnique from '../assets/icons/blender.svg';
import autoProducts from '../assets/icons/car.svg';
import sport from '../assets/icons/activity-running.svg';
import other from '../assets/icons/other.svg';

export default [
  smartPhones,
  compTechnique,
  gadgets,
  conditioners,
  tv,
  audioWiFi,
  homeTechnique,
  officeTechnique,
  kitchenTechnique,
  autoProducts,
  sport,
  other,
];