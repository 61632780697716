<template>
  <ul>
    <li
      v-for="(category, index) in categories"
      :key="category.id"
    >

      <pu-skeleton v-if="loading" class="skeleton-loader" tag="div" height="100%" :key="category.id"></pu-skeleton>

      <div v-else class="list" @click="filter(category.id, category.locale.title)">

        <div class="icon">
          <img
            :src="categoryIcons[index < 12 ? index : 11]"
            :alt="category.locale.title"
          >
        </div>

        <div class="title">{{ category.locale.title }}</div>

      </div>
    </li>
  </ul>
</template>

<script>
import VueTypes from "vue-types";
import categoryIcons from "../../data/category-icons";

export default {
  name: "CategoriesList",
  props: {
    categories: VueTypes.array.isRequired,
    loading: VueTypes.bool.def(false)
  },
  data() {
    return {
      categoryIcons
    };
  },
  methods: {
    filter(categoryId, categoryTitle) {
      this.$emit("onChange", { categoryId, categoryTitle });
    }
  }
};
</script>

<style lang="scss" scoped>
ul {

  li {

    .skeleton-loader {
      height: 40px;
      margin: 10px 0;
    }

    .list {
      display: flex;
      align-items: center;
      margin-top: 12px;
      margin-bottom: 12px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: $main;
      }

      .icon {
        min-width: 32px;
        min-height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $main;
        border-radius: 8px;
        margin-right: 8px;
      }
    }
  }
}
</style>