<template>
  <router-link
    tag="div"
    class="card"
    :style="`background: url('https://picsum.photos/280/220?random=${id}') no-repeat center center / cover`"
    :to="{ name: 'partner', params: { partnerId: id } }"
  >

    <div class="logo-title-section">
      <div class="title-category-section">
        <div class="card-title" v-html="title"></div>
      </div>
    </div>

    <div class="overlay"></div>

  </router-link>
</template>

<script>
import VueTypes from 'vue-types';
import imagePlaceholder from '../assets/img/image-placeholder.png'

export default {
  data() {
    return {
      defaultLogo: imagePlaceholder,
      storageDomain: process.env.VUE_APP_STORAGE_DOMAIN,
    };
  },
  props: {
    id: VueTypes.number.isRequired,
    logo: VueTypes.string.def,
    title: VueTypes.string.isRequired,
    partnerCategories: VueTypes.array.isRequired,
    categories: VueTypes.array.isRequired,
  },
};
</script>
<style lang="scss" scoped>
  .card {
    position: relative;
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 32px;
    overflow: hidden;
    height: 220px;
    color: $white;
    cursor: pointer;
    transition: box-shadow .2s ease-in;

    &:hover {
      box-shadow: 0 7px 12px rgba(0, 0, 0, 0.35);
    }

    .overlay {
      position: absolute;
      left: 0;
      top: 40%;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 18.22%, rgba(0, 0, 0, 0.72) 71.96%);
      z-index: 1;
    }

    .logo-title-section {
      position: absolute;
      bottom: 18px;
      display: flex;
      align-items: center;
      z-index: 2;

      .title-category-section {
        max-width: 100%;
        margin-left: 12px;

        .card-title {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
</style>
